import { render, staticRenderFns } from "./topRoof.vue?vue&type=template&id=723d7eb6&scoped=true&"
import script from "./topRoof.vue?vue&type=script&lang=js&"
export * from "./topRoof.vue?vue&type=script&lang=js&"
import style0 from "./topRoof.vue?vue&type=style&index=0&id=723d7eb6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "723d7eb6",
  null
  
)

export default component.exports