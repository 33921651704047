<template>
  <div>
    <div class="roofbox">
      <el-card class="management">
        <div class="management-card-head" slot="header">
          <p>顶部设置</p>
        </div>
        <div class="roof_wrap">
          <div class="management_basics_form">
            <div class="management_basics_form_item d-flex" v-if="false">
              <div class="management_basics_form_label">前台LOGO</div>
              <div class="">
                <div
                  class="logo_container"
                  v-if="!headerTop['pc.client.logo_url']"
                  @click.stop="imgUpload('pc.client.logo_url')"
                >
                  <span>+</span>
                </div>
                <div
                  class="prospect_url"
                  v-else
                  @click.stop="imgUpload('pc.client.logo_url')"
                >
                  <img :src="headerTop['pc.client.logo_url']" alt="" />
                </div>
                <div class="logo_toast">建议尺寸：460*92像素</div>
              </div>
            </div>
            <div class="management_basics_form_item d-flex">
              <div class="management_basics_form_label">前景图片</div>
              <div class="">
                <div
                  class="logo_container"
                  v-if="!headerTop['pc.top.prospect_url']"
                  @click.stop="imgUpload('pc.top.prospect_url')"
                >
                  <span>+</span>
                </div>
                <div
                  class="login_url"
                  v-else
                  @click.stop="imgUpload('pc.top.prospect_url')"
                >
                  <img :src="headerTop['pc.top.prospect_url']" alt="" />
                </div>
                <div class="logo_toast">建议尺寸：1920*500像素。</div>
              </div>
            </div>
            <div class="management_basics_form_item d-flex">
              <div class="management_basics_form_label">风格颜色</div>
              <div>
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center login_color">
                    <el-color-picker v-model="headerTop['pc.login.origin_color']"></el-color-picker>
                    <el-input v-model="headerTop['pc.login.origin_color']" readonly></el-input>
                  </div>
                  ——
                  <div class="d-flex align-items-center login_color">
                    <el-color-picker v-model="headerTop['pc.login.change_color']"></el-color-picker>
                    <el-input v-model="headerTop['pc.login.change_color']" readonly></el-input>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="management_basics_form_item d-flex">
              <div class="management_basics_form_label">效果图</div>
              <div>
                <div class="result_effect" :style="{background:resultEffect}"></div>
              </div>
            </div>
            <div class="management_basics_form_item d-flex">
              <div class="management_basics_form_label">是否显示</div>
              <div class="">
                <div class="">
                  <el-radio-group v-model="headerTop['pc.promotion.open']">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="0">不显示</el-radio>
                  </el-radio-group>
                </div>
                <div class="logo_toast">
                  控制PC端首页顶部推广图是否显示(设置完成后，清理缓存即生效)
                </div>
              </div>
            </div>
            <template v-if="headerTop['pc.promotion.open'] == 1">
              <div class="management_basics_form_item d-flex">
                <div class="management_basics_form_label">背景颜色</div>
                <div class="">
                  <div class="d-flex align-items-center background_color">
                    <el-color-picker
                      v-model="headerTop['pc.promotion.background_color']"
                    ></el-color-picker>
                    <el-input
                      v-model="headerTop['pc.promotion.background_color']"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div class="management_basics_form_item d-flex">
                <div class="management_basics_form_label">顶部推广图</div>
                <div>
                  <div
                    class="logo_container"
                    v-if="!headerTop['pc.promotion.image_url']"
                    @click.stop="imgUpload('pc.promotion.image_url')"
                  >
                    <span>+</span>
                  </div>
                  <div
                    class="promotion_url"
                    v-else
                    @click.stop="imgUpload('pc.promotion.image_url')"
                  >
                    <img :src="headerTop['pc.promotion.image_url']" alt="" />
                  </div>
                  <div class="logo_toast">建议尺寸：1920*80像素。</div>
                </div>
              </div>
              <div class="management_basics_form_item d-flex">
                <div class="management_basics_form_label">顶部推广链接</div>
                <div>
                  <div>
                    <el-input
                      v-model="headerTop['pc.promotion.image_link']"
                      prefix-icon="el-icon-link"
                      placeholder="请选择推广链接"
                    ></el-input>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-card>
    </div>

    <diy-footer-button v-if="hasPerm(['pc.top.update'])">
      <el-button type="primary" @click.stop="preview" :loading="caijiLoading"
        >保存</el-button
      >
    </diy-footer-button>
    <pictureSelect
      ref="pictureSelect"
      :multipled="is_multiple"
      @change="imgbrand($event)"
    ></pictureSelect>
    <linkUrl ref="selectLink" @change="linkGet"></linkUrl>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_multiple: 0,
      headerTop: {},
      headType: "",
      caijiLoading: false,
      resultEffect:'',
    };
  },
  computed: {
    common_info: {
      get() {
        let stateuser = {
          "pc.config.list": {},
          "cat.list": [],
          "goods.status.list": [],
        };
        let val = this.$store.state.noLoginGlobal || stateuser;
        return val;
      },
      set() {},
    },
  },
  watch:{
    headerTop:{
      handler(newVal,oldVal){
        if(newVal['pc.login.origin_color']&&newVal['pc.login.change_color']){
          this.resultEffect="linear-gradient(270deg," + newVal['pc.login.change_color'] + " 0%," + newVal['pc.login.origin_color'] + " 100%)";
        }else{
          this.resultEffect=newVal['pc.login.origin_color']||newVal['pc.login.change_color']
        }
      },
      deep:true,
      immediate:true,
    }
  },
  created() {
    this.getTopHead();
  },
  methods: {
    getTopHead() {
      let that = this;
      this.$get(this.$apis.getPcTopHead)
        .then((res) => {
          if (!res.data["pc.login.background_url"]) {
            res.data["pc.login.background_url"] =
              that.common_info["default.config.list"][
                "pc.default.prospect_pic"
              ];
          }
          if (!res.data["pc.top.prospect_url"]) {
            res.data["pc.top.prospect_url"] =
              that.common_info["default.config.list"]["pc.default.top_default"];
          }
          that.headerTop = res.data;
        })
        .catch((err) => {
          //console.log(err);
          that.common.message(that, err.message);
        });
    },
    imgUpload(type) {
      this.headType = type;
      this.$refs.pictureSelect.photoVisible =
        !this.$refs.pictureSelect.photoVisible;
    },
    extension(type) {
      this.headType = type;
      this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
    },
    addnavigation() {
      let obj = {
        url: "",
        title: "",
      };
      this.roofList.push(obj);
    },
    delroofurl(index) {
      this.roofList.splice(index, 1);
    },
    trenchTop(index) {
      this.trenchHedaline = index;
    },
    ditchTrench(index, inx) {
      this.trenchPage = index;
      this.ditchPage = inx;
    },
    imgbrand(value) {
      this.headerTop["" + this.headType] = value[0].file_url;
    },
    linkGet(value) {
      this.headerTop["" + this.headType] = value.link;
    },
    preview() {
      let that = this;
      if(!this.headerTop['pc.login.origin_color']){
        this.headerTop['pc.login.origin_color']=''
      }
      if(!this.headerTop['pc.login.change_color']){
        this.headerTop['pc.login.change_color']=''
      }
      let data = this.headerTop;
      this.caijiLoading = true;
      this.$put(this.$apis.getPcTopHead, data)
        .then((res) => {
          that.caijiLoading = false;
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "保存成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          } else {
            that.common.message(that, res.message);
          }
        })
        .catch((err) => {
          that.caijiLoading = false;
          that.common.message(that, err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/* 顶部设置 */
.roofbox {
  background: #fff;
  border-radius: 4px;
  position: relative;
  // margin-bottom: 80px;
  transition: all 0.2s ease-in-out;
}
.management {
  flex: 1;
}
.management_basics {
  background: #fff;
  border-radius: 4px;
  position: relative;
  transition: all 0.2s ease-in-out;
  // margin-bottom: 2px;

  padding-bottom: 32px;
  flex: 1;
}
.management_basics_body {
  padding: 14px 20px;

  .service_title {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
}
.management_basics_form_item {
  margin-bottom: 24px;
  vertical-align: top;
  zoom: 1;
  .background_color {
    /deep/.el-input {
      margin-left: 10px;
      height: 36px;
      line-height: 36px;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
      }
    }
  }
  .promotion_url {
    width: 500px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.management_basics_form .management_basics_form_item:last-child {
  margin-bottom: 0;
}
.management_basics_form_label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 1;
  padding: 10px 20px 10px 0;
  box-sizing: border-box;
  width: 106px;
}
.management_basics_form_text {
  color: var(--fontColor);
  font-size: 14px;
  cursor: pointer;
}
.roof_wrap {
  padding: 20px;
}
.roof_manin {
  position: relative;
  width: 428px;
  display: flex;
  background: #f4f3f7;
  margin-bottom: 8px;
  padding: 20px;
}
.roof_input .el-input {
  width: 180px;
}
.roof_url {
  flex: 1 1 0%;
  width: 100%;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  background: #e8effc;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #666;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 22px;
  margin-left: 10px;
  font-size: 14px;
}
.roof_url .roof_url_link {
  font-size: 14px;
  margin-right: 3px;
}
.roof_url .roof_url_close {
  font-size: 25px;
}
.roof_close {
  visibility: hidden;
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
}
.roof_manin:hover .roof_close {
  visibility: visible;
}
.roof_close i {
  font-size: 16px;
}
.trench {
  padding: 16px 20px;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  max-height: 500px;
  min-height: 108px;
  overflow-y: auto;
  max-height: 620px;
  overflow: visible;
}
.trench_wrap {
  height: 500px;
  position: relative;
}
.trench_head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
}
.trench_head .trench_head_btn {
  float: left;
  border-radius: 4px;
  margin-right: 10px;
  width: 88px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #f1f1f1;
  color: #666;
  cursor: pointer;
}
.trench_head .trench_head_btn.active {
  border: none;
  background-color: var(--fontColor, #fb6638);
  color: #fff;
}
.trench_left {
  top: 45px;
  width: 160px;
  height: 455px;
  color: #333;
  float: left;
  position: relative;
  border: 1px solid #f1f1f1;
  overflow: auto;
  overflow-x: hidden;
  border-radius: 4px;
  padding: 10px 0 10px 0;
}
.dividegoods {
  height: 36px;
  line-height: 36px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 0 25px;
  position: relative;
}
.trench_left .dividegoods.active {
  color: var(--fontColor, #fb6638);
  background: var(--primary-tips, #fff3ef);
}
.trench_right {
  position: absolute;
  top: 45px;
  left: 170px;
  right: 0;
  bottom: 0;
  border: 1px solid #f1f1f1;
  overflow: auto;
  overflow-x: hidden;
  border-radius: 4px;
  padding: 10px;
}
.trench_page_box .url_page_title {
  line-height: 43px;
  height: 43px;
}
.trench_page_list {
  overflow: hidden;
}
.trench_page_list > div {
  float: left;
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin: 5px 9px 5px 0;
  cursor: pointer;
  position: relative;
}
.trench_page_list > div.active {
  border-color: var(--fontColor, #fb6638);
}
.trench_page_list > div p {
  max-width: 130px;
}
.trench_page_list > div i {
  position: absolute;
  right: -11px;
  bottom: -10px;
  color: #fff;
  font-size: 21px;
  z-index: 50;
  visibility: hidden;
  transform: scale(0.7);
}
.trench_page_list > div i.active {
  color: var(--fontColor);
  visibility: visible;
}
.management {
  flex: 1;
}
.management-card-head {
  // border-bottom: 1px solid #f2f2f2;
  // padding: 8px 20px;
  line-height: 1;
}
.management-card-head p {
  display: inline-block;
  width: auto;
  line-height: 28px;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}
.management_basics_form_label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 1;
  padding: 10px 20px 10px 0;
  box-sizing: border-box;
  width: 106px;
}
.management_foot {
  position: fixed;
  left: 240px;
  right: 0;
  bottom: 0;
  min-width: 980px;
  margin: 0 24px;
  height: auto;
  border-top: 1px solid #f1f1f1;
  z-index: 100;
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.management_foot_body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.logo_container {
  width: 78px;
  height: 78px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 28px;
    color: #dad9d9;
  }
}
.prospect_url {
  width: 187px;
  height: 55px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.logo_toast {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.login_url {
  width: 513px;
  height: 310px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.login_color{
  /deep/.el-input{
    width: 90px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.login_color:nth-child(n+1){
  margin-left: 10px;
}
.result_effect{
  width: 100px;
  height: 50px; 
}
</style>